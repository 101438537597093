import { IUser, IUserDoc } from '@domain/User'
import { ITierDoc } from '@domain/Tier'

export default class Member {
    private state: IUser = {}

    get _id() {
        return this.state._id
    }

    get email() {
        return this.state.email
    }

    get password() {
        return this.state.password
    }

    get firstName() {
        return this.state.firstName
    }

    get lastName() {
        return this.state.lastName
    }

    get scope() {
        return this.state.scope
    }

    get channelName() {
        return this.state.channelName
    }

    get channelUrl() {
        return this.state.channelUrl
    }

    get streamingPlatform() {
        return this.state.streamingPlatform
    }

    get twitchLinked() {
        return this.state.twitchLinked
    }

    get twitchUsername() {
        return this.state.twitchUsername
    }

    get tier() {
        return this.state.tier
    }

    get claimed() {
        return this.state.claimed
    }

    get verified() {
        return this.state.verified
    }

    get createdAt() {
        return this.state.createdAt
    }

    get updatedAt() {
        return this.state.updatedAt
    }

    get deletedAt() {
        return this.state.deletedAt
    }

    get changed() {
        return this.state.changed
    }

    get data() {
        return {
            _id: this.state._id,
            email: this.state.email,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            scope: this.state.scope,
            channelName: this.state.channelName,
            channelUrl: this.state.channelUrl,
            streamingPlatform: this.state.streamingPlatform,
            twitchLinked: this.state.twitchLinked,
            twitchUsername: this.state.twitchUsername,
            tier: this.state?.tier?._id,
            claimed: this.state.claimed,
            verified: this.state.verified,
            createdAt: this.state.createdAt,
            updatedAt: this.state.updatedAt,
            deletedAt: this.state.deletedAt,
            changed: this.state.changed,
        }
    }

    constructor(data: IUserDoc | null) {
        if (data === null) return

        this.set('_id', data._id)
        this.set('email', data.email)
        this.set('password', data.password)
        this.set('firstName', data.firstName)
        this.set('lastName', data.lastName)
        this.set('scope', data.scope)
        this.set('channelName', data.channelName)
        this.set('channelUrl', data.channelUrl)
        this.set('streamingPlatform', data.streamingPlatform)
        this.set('twitchLinked', data.twitchLinked)
        this.set('twitchUsername', data.twitchUsername)
        this.set('tier', data.tier)
        this.set('claimed', data.claimed)
        this.set('verified', data.verified)
        this.set('createdAt', data.createdAt)
        this.set('updatedAt', data.updatedAt)
        this.set('deletedAt', data.deletedAt)

        this.setChanged(false)
    }

    setId(_id?: string) {
        this.state._id = _id
    }

    setEmail(email?: string) {
        this.state.email = email
    }

    setPassword(password?: string) {
        this.state.password = password
    }

    setFirstName(firstName?: string) {
        this.state.firstName = firstName
    }

    setLastName(lastName?: string) {
        this.state.lastName = lastName
    }

    setScope(scope?: string) {
        this.state.scope = scope
    }

    setChannelName(channelName?: string) {
        this.state.channelName = channelName
    }

    setChannelUrl(channelUrl?: string) {
        this.state.channelUrl = channelUrl
    }

    setStreamingPlatform(streamingPlatform?: string) {
        this.state.streamingPlatform = streamingPlatform
    }

    setTwitchLinked(twitchLinked?: boolean) {
        this.state.twitchLinked = twitchLinked
    }

    setTwitchMembername(twitchUsername?: string) {
        this.state.twitchUsername = twitchUsername
    }

    setTier(tier: ITierDoc) {
        this.state.tier = tier
    }

    setClaimed(claimed?: boolean) {
        this.state.claimed = claimed
    }

    setVerified(verified?: boolean) {
        this.state.verified = verified
    }

    setCreatedAt(createdAt?: Date) {
        this.state.createdAt = createdAt
    }

    setUpdatedAt(updatedAt?: Date) {
        this.state.updatedAt = updatedAt
    }

    setDeletedAt(deletedAt?: Date) {
        this.state.deletedAt = deletedAt
    }

    setChanged(changed?: boolean) {
        this.state.changed = changed
    }

    set(
        field: string,
        value: string | number | Date | boolean | undefined | ITierDoc
    ) {
        switch (field) {
            case '_id':
                if (typeof value === 'string') this.setId(value)
                break

            case 'email':
                if (typeof value === 'string') this.setEmail(value)
                break

            case 'password':
                if (typeof value === 'string') this.setPassword(value)
                break

            case 'firstName':
                if (typeof value === 'string') this.setFirstName(value)
                break

            case 'lastName':
                if (typeof value === 'string') this.setLastName(value)
                break

            case 'scope':
                if (typeof value === 'string') this.setScope(value)
                break

            case 'channelName':
                if (typeof value === 'string') this.setChannelName(value)
                break

            case 'channelUrl':
                if (typeof value === 'string') this.setChannelUrl(value)
                break

            case 'streamingPlatform':
                if (typeof value === 'string') this.setStreamingPlatform(value)
                break

            case 'twitchLinked':
                if (typeof value === 'boolean') this.setTwitchLinked(value)
                break

            case 'twitchUsername':
                if (typeof value === 'string') this.setTwitchMembername(value)
                break

            case 'tier':
                if (
                    value &&
                    typeof value !== 'string' &&
                    typeof value !== 'number' &&
                    typeof value !== 'boolean' &&
                    !(value instanceof Date)
                )
                    this.setTier(value)
                break

            case 'claimed':
                if (typeof value === 'boolean') this.setClaimed(value)
                break

            case 'verified':
                if (typeof value === 'boolean') this.setVerified(value)
                break

            case 'createdAt':
                if (typeof value === 'string')
                    this.setCreatedAt(new Date(value))
                break

            case 'updatedAt':
                if (typeof value === 'string')
                    this.setUpdatedAt(new Date(value))
                break

            case 'deletedAt':
                if (typeof value === 'string')
                    this.setDeletedAt(new Date(value))
                break

            case 'changed':
                if (typeof value === 'boolean') this.setChanged(value)
                break
        }

        if (field !== 'changed') this.setChanged(true)

        return this
    }

    valid() {
        return true
    }
}
