import { IUser } from '@domain/User'
import { IMemberProviderStates, IMemberProviderDto } from '@domain/Member'
import Member from '@classes/Member'

const init = (
    state: IMemberProviderStates,
    data: IMemberProviderDto | null
) => {
    return data
        ? {
              ...state,
              ...data,
              member: data?.member
                  ? new Member(data?.member)
                  : new Member(null),
          }
        : state
}

const reducer = (state: any, { type, ...data }: any) => {
    switch (type) {
        case 'sync':
            return init(state, data)

        case 'updateUser':
            return {
                ...state,
                member: state.member.set(data.field, data.value),
            }

        default:
            return state
    }
}

export { init, reducer }
