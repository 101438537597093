import React from 'react'
import { useAppState } from '@context/AppProvider'
import { Navigate } from 'react-router'

interface IOnboarding {
    path: string
    children: React.ReactElement
}

const Onboarding = ({ path, children }: IOnboarding) => {
    const { socket, user, handleLogout } = useAppState()

    if (!user) {
        if (!['signup', 'login'].includes(path))
            return <Navigate to="/onboarding/login" />
    } else {
        if (user?.verified && user?.claimed) return <Navigate to="/" />

        if (!user?.claimed && path !== 'claim-account')
            return <Navigate to="/onboarding/claim-account" />

        if (user?.claimed && !user?.verified && path !== 'pending-verification')
            return <Navigate to="/onboarding/pending-verification" />
    }

    return (
        <>
            {children}

            {socket !== null && user !== null ? (
                <button
                    type="button"
                    className="flex items-center justify-center h-8 px-4 fixed top-4 right-4 border border-black text-center"
                    onClick={handleLogout}
                >
                    Log out
                </button>
            ) : null}
        </>
    )
}

export default Onboarding
