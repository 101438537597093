import * as React from 'react'
import Private from '@hoc/Private'
import BaseLayout from '@hoc/Layout/Base'

const Downloads: React.FC = () => {
    return (
        <Private>
            <BaseLayout>Downloads</BaseLayout>
        </Private>
    )
}

export default Downloads
