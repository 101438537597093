import * as React from 'react'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import Onboarding from '@hoc/Onboarding'
import Link from '@components/Link'

const AuthLogin = () => {
    const [cookies, setCookie, removeCookie] = useCookies([
        'swccp_access_token',
    ])
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [message, setMessage] = useState<string>('')

    const handleSubmit = async () => {
        const response = await fetch('/api/auth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        })

        const data = await response.json()

        if (data.token) {
            setCookie('swccp_access_token', data.token, { path: '/' })
        } else {
            removeCookie('swccp_access_token', { path: '/' })
            setMessage(data.message)
        }
    }

    return (
        <Onboarding path="login">
            <div className="flex flex-col items-center justify-center w-full h-full fixed top-0 left-0 text-center">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <div className="flex flex-col -m-2">
                        <div className="p-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={({ currentTarget: { value } }) =>
                                    setEmail(value)
                                }
                                className="flex items-center h-9 px-3 border border-black"
                            />
                        </div>
                        <div className="p-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={({ currentTarget: { value } }) =>
                                    setPassword(value)
                                }
                                className="flex items-center h-9 px-3 border border-black"
                            />
                        </div>

                        <div className="p-2">
                            <button
                                type="submit"
                                className="flex justify-center items-center w-full h-9 px-3 bg-black text-white text-center"
                            >
                                Log In
                            </button>
                        </div>

                        {message ? (
                            <div className="p-2">
                                <p className="px-2 py-1 border-2 border-black font-medium">
                                    {message}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </form>
                <div className="flex items-center justify-center text-center mt-8">
                    Need an account?{' '}
                    <Link
                        to="/onboarding/signup"
                        className="ml-2 font-semibold underline"
                    >
                        Sign up
                    </Link>
                    .
                </div>
            </div>
        </Onboarding>
    )
}

export default AuthLogin
