import React from 'react'

const NotFound: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full h-full fixed top-0 left-0 text-center">
            <p>NOT FOUND</p>
        </div>
    )
}

export default NotFound
