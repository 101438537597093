import * as React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { ITierDoc } from '@domain/Tier'
import Onboarding from '@hoc/Onboarding'
import Checkbox from '@components/Checkbox'
import Link from '@components/Link'

const AuthSignup = () => {
    const [cookies, setCookie, removeCookie] = useCookies([
        'swccp_access_token',
    ])
    const [firstName, setFirstName] = useState<string>('Shane')
    const [lastName, setLastName] = useState<string>('Welland')
    const [streamingName, setStreamingName] = useState<string>('Docco')
    const [streamingPlatform, setStreamingPlatform] = useState<string>('Twitch')
    const [email, setEmail] = useState<string>('shane.welland.88@gmail.com')
    const [channelUrl, setChannelUrl] = useState<string>('twitch.tv/docccoo')
    const [tier, setTier] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')
    const [legal, setLegal] = useState<boolean>(false)
    const [stepIndex, setStepIndex] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [tiers, setTiers] = useState<ITierDoc[]>([])

    useEffect(() => {
        const fetchTiers = async () => {
            const response = await fetch('/api/tiers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const { tiers } = await response.json()
            setTiers(tiers)
            setLoading(false)
        }

        fetchTiers()
    }, [])

    const handleNext = (event: React.SyntheticEvent) => {
        event.preventDefault()
        setStepIndex(stepIndex + 1)
    }

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        const response = await fetch('/api/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName,
                lastName,
                streamingName,
                streamingPlatform,
                email,
                channelUrl,
                tier,
                password,
                passwordConfirm,
                legal,
            }),
        })

        const data = await response.json()

        if (data.token) {
            setCookie('swccp_access_token', data.token, { path: '/' })
        } else {
            removeCookie('swccp_access_token', { path: '/' })
        }
    }

    return (
        <Onboarding path="signup">
            <div className="flex flex-col items-center justify-center w-screen h-screen p-8 fixed top-0 left-0 text-center">
                <h2 className="max-w-lg mx-auto mb-8 p-2 text-xl font-bold text-center">
                    Apply for the Star Wars: Hunters Creator Program
                </h2>

                {stepIndex === 0 ? (
                    <form
                        className="flex justify-end flex-wrap -m-2 max-w-lg"
                        onSubmit={handleNext}
                    >
                        <div className="w-full md:w-1/2 p-2">
                            <input
                                required
                                value={firstName}
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setFirstName(value)
                                }
                            />
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <input
                                required
                                value={lastName}
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setLastName(value)
                                }
                            />
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <input
                                required
                                value={streamingName}
                                type="text"
                                name="streamingName"
                                placeholder="Streaming Name"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setStreamingName(value)
                                }
                            />
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <select
                                disabled
                                required
                                value={streamingPlatform}
                                id="streamingPlatform"
                                name="streamingPlatform"
                                placeholder="Select a Platform"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setStreamingPlatform(value)
                                }
                            >
                                <option value="Twitch">Twitch</option>
                                <option value="Youtube">Youtube</option>
                            </select>
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <input
                                required
                                value={email}
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setEmail(value)
                                }
                            />
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <input
                                required
                                value={channelUrl}
                                type="text"
                                name="channelUrl"
                                placeholder="Channel URL"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setChannelUrl(value)
                                }
                            />
                        </div>

                        <div className="w-full md:w-1/3 p-2">
                            {firstName &&
                            lastName &&
                            streamingName &&
                            streamingPlatform &&
                            email &&
                            channelUrl ? (
                                <button
                                    type="submit"
                                    className="flex justify-center items-center w-full h-9 px-3 bg-black text-white text-center"
                                >
                                    Next
                                </button>
                            ) : (
                                <div className="flex justify-center items-center w-full h-9 px-3 bg-black bg-opacity-50 text-white text-center cursor-not-allowed">
                                    Next
                                </div>
                            )}
                        </div>
                    </form>
                ) : null}

                {stepIndex === 1 ? (
                    <form
                        className="flex justify-end flex-wrap -m-2 max-w-lg"
                        onSubmit={handleNext}
                    >
                        <div className="w-full p-2">
                            {loading ? (
                                <span>Finding tiers...</span>
                            ) : (
                                <select
                                    required
                                    value={tier}
                                    id="tier"
                                    name="tier"
                                    placeholder="Select a tier"
                                    className="flex items-center w-full h-9 px-3 border border-black"
                                    onChange={({ currentTarget: { value } }) =>
                                        setTier(value)
                                    }
                                >
                                    <option value="">Select a tier...</option>
                                    {tiers.map(({ _id, name }: ITierDoc) => (
                                        <option value={_id}>{name}</option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="w-full p-2">
                            {tier ? (
                                <button
                                    type="submit"
                                    className="flex justify-center items-center w-full h-9 px-3 bg-black text-white text-center"
                                >
                                    Next
                                </button>
                            ) : (
                                <div className="flex justify-center items-center w-full h-9 px-3 bg-black bg-opacity-50 text-white text-center cursor-not-allowed">
                                    Next
                                </div>
                            )}
                        </div>
                    </form>
                ) : null}

                {stepIndex === 2 ? (
                    <form
                        className="flex justify-end flex-wrap -m-2 max-w-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="w-full p-2">
                            <input
                                required
                                value={password}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setPassword(value)
                                }
                            />
                        </div>
                        <div className="w-full p-2">
                            <input
                                required
                                value={passwordConfirm}
                                type="password"
                                name="passwordConfirm"
                                id="passwordConfirm"
                                placeholder="Confirm Password"
                                className="flex items-center w-full h-9 px-3 border border-black"
                                onChange={({ currentTarget: { value } }) =>
                                    setPasswordConfirm(value)
                                }
                            />
                        </div>
                        <div className="w-full p-2">
                            <Checkbox checked={legal} setChecked={setLegal} />
                        </div>
                        <div className="w-full p-2">
                            {password &&
                            passwordConfirm &&
                            password === passwordConfirm &&
                            legal ? (
                                <button
                                    type="submit"
                                    className="flex justify-center items-center w-full h-9 px-3 bg-black text-white text-center"
                                >
                                    Next
                                </button>
                            ) : (
                                <div className="flex justify-center items-center w-full h-9 px-3 bg-black bg-opacity-50 text-white text-center">
                                    Next
                                </div>
                            )}
                        </div>
                    </form>
                ) : null}

                <div className="flex items-center justify-center text-center mt-8">
                    Already have an account?{' '}
                    <Link
                        to="/onboarding/login"
                        className="ml-2 font-semibold underline"
                    >
                        Log In
                    </Link>
                    .
                </div>
            </div>
        </Onboarding>
    )
}

export default AuthSignup
