import * as React from 'react'
import Private from '@hoc/Private'
import BaseLayout from '@hoc/Layout/Base'
import { useAppState } from '@context/AppProvider'

const Dashboard: React.FC = () => {
    const { user } = useAppState()

    return (
        <Private>
            <BaseLayout>
                <div className="flex flex-wrap -m-4 xl:-m-8">
                    {user?.twitchLinked ? (
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                            <div className="p-4 xl:p-8 border-2 border-twitch font-semibold text-twitch text-center">
                                <span>&#x2713;</span> Twitch Linked
                            </div>
                        </div>
                    ) : (
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                            <a
                                href="/api/auth/twitch"
                                className="block p-4 xl:p-8 border-2 border-twitch bg-twitch font-semibold text-center text-white"
                            >
                                Link Twitch
                            </a>
                        </div>
                    )}
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-4 xl:p-8">
                        <div className="p-4 xl:p-8 border border-black">
                            xxxx
                        </div>
                    </div>
                </div>
            </BaseLayout>
        </Private>
    )
}

export default Dashboard
