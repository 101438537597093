import * as React from 'react'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { useAppState } from '@context/AppProvider'
import Tier from '@classes/Tier'
import Link from '@components/Link'

export default () => {
    const { loading, tiers } = useAppState()

    return (
        <Private>
            <AdminLayout>
                <div className="flex items-center mb-8">
                    <h1 className="mr-8 text-2xl font-bold">Tiers</h1>
                    <Link
                        to="/admin/tiers/new"
                        className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                    >
                        Add New
                    </Link>
                </div>

                {!loading ? (
                    <>
                        {tiers?.map((tier: Tier) => (
                            <Link
                                key={tier._id}
                                to={`/admin/tiers/${tier._id}`}
                                className="flex h-10 items-center justify-start border-b border-black"
                            >
                                <div>{tier.name}</div>
                                <div
                                    className={`ml-8 ${
                                        tier.limit && tier.count >= tier.limit
                                            ? 'text-red'
                                            : 'text-black'
                                    }`}
                                >
                                    <span>{tier.count}</span>/
                                    <span>{tier.limit}</span>
                                </div>
                            </Link>
                        ))}
                    </>
                ) : (
                    <p>Loading</p>
                )}
            </AdminLayout>
        </Private>
    )
}
