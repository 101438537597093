import * as React from 'react'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { useAppState } from '@context/AppProvider'
import User from '@classes/User'
import Link from '@components/Link'

const AdminUsers: React.FC = () => {
    const { users: _users } = useAppState()
    const users = _users?.filter(({ scope }) => scope === 'super')

    return (
        <Private>
            <AdminLayout>
                <div className="flex items-center mb-8">
                    <h1 className="mr-8 text-2xl font-bold">Users</h1>
                    <Link
                        to="/admin/users/new"
                        className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                    >
                        Add New
                    </Link>
                </div>

                <div>
                    {users?.map((user?: User) =>
                        user ? (
                            <Link
                                key={user._id}
                                to={`/admin/users/${user._id}`}
                                className="flex h-16 items-center justify-start border-b border-black"
                            >
                                <span>
                                    {user.firstName} {user.lastName}
                                </span>
                                <span className="ml-4">{user.email}</span>
                                <span className="ml-4">{user.scope}</span>
                            </Link>
                        ) : null
                    )}
                </div>
            </AdminLayout>
        </Private>
    )
}

export default AdminUsers
