import React from 'react'

interface ICheckbox {
    checked: boolean
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
    className?: string
}

const Checkbox = ({ checked, setChecked, className }: ICheckbox) => {
    return (
        <label
            htmlFor="legal"
            className={`flex items-center text-left cursor-pointer select-none ${className}`}
        >
            <span className="flex-1">Legal checkbox</span>
            <div className="relative">
                <span className="inline-flex w-9 h-9 relative border border-black">
                    <span
                        className={`block w-4 h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                            checked ? 'bg-black' : 'bg-transparent'
                        }`}
                    />
                </span>
                <input
                    required
                    checked={checked}
                    type="checkbox"
                    name="legal"
                    id="legal"
                    placeholder="Confirm Password"
                    className="w-9 h-9 absolute top-0 left-0 opacity-0"
                    onChange={() => setChecked(!checked)}
                />
            </div>
        </label>
    )
}

export default Checkbox
