import * as React from 'react'
import Private from '@hoc/Private'
import BaseLayout from '@hoc/Layout/Base'
import { useAppState } from '@context/AppProvider'
import { UserProvider, useUserState } from '@context/UserProvider'

import Link from '@components/Link'

const Account = () => {
    const { socket, users, tiers } = useAppState()
    const { dispatch, user } = useUserState()

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        if (socket === null || !user) return

        await socket.emit('user_update_profile', user._id, user.data, () => {
            alert('UPDATED')
        })
    }

    return (
        <Private>
            <BaseLayout>
                <h1 className="text-2xl font-bold">
                    {user?.firstName} {user?.lastName}
                </h1>
                <Link to="/admin/users" className="inline-flex mb-8">
                    Back to dashboard
                </Link>

                <form
                    className="flex flex-col items-start justify-start"
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col">
                        <strong>EMAIL:</strong>{' '}
                        <input
                            type="text"
                            value={user?.email}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'email',
                                    value,
                                })
                            }
                            disabled
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>FIRST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.firstName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'firstName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>LAST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.lastName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'lastName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>CHANNEL NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.channelName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'channelName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>CHANNEL URL:</strong>{' '}
                        <input
                            type="text"
                            value={user?.channelUrl}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'channelUrl',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>STREAMING PLATFORM:</strong>{' '}
                        <select
                            name="streamingPlatform"
                            value={user?.streamingPlatform}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'streamingPlatform',
                                    value,
                                })
                            }
                        >
                            <option value="Twitch">Twitch</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <strong>TIER:</strong> <span>{user?.tier?.name}</span>
                    </div>
                    <div className="flex flex-col">
                        <strong>CREATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(user?.createdAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>UPDATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(user?.updatedAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row items-center mt-4">
                        {user?.changed ? (
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                            >
                                Save
                            </button>
                        ) : (
                            <div className="inline-flex items-center justify-center h-9 px-4 bg-black bg-opacity-10 text-white text-center cursor-not-allowed">
                                Save
                            </div>
                        )}
                    </div>
                </form>
            </BaseLayout>
        </Private>
    )
}

export default () => {
    const { user } = useAppState()

    return (
        <UserProvider {...{ userId: user?._id }}>
            <Account />
        </UserProvider>
    )
}
