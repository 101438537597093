import * as React from 'react'
import { Navigate } from 'react-router'

import { useAppState } from '@context/AppProvider'

interface IPrivate {
    children: React.ReactNode
}

const Private = ({ children }: IPrivate) => {
    const { socket, user } = useAppState()

    if (!user || socket === null) return <Navigate to="/onboarding/login" />
    if (!user?.claimed) return <Navigate to="/onboarding/claim-account" />
    if (!user?.verified)
        return <Navigate to="/onboarding/pending-verification" />

    return <>{children}</>
}

export default Private
