import * as React from 'react'
import { useParams } from 'react-router-dom'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { useAppState } from '@context/AppProvider'
import { TierProvider, useTierState } from '@context/TierProvider'
import Link from '@components/Link'

const AdminTier: React.FC = () => {
    const { socket } = useAppState()
    const { dispatch, tier } = useTierState()

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        if (socket === null || tier === null) return

        await socket.emit('tier_update', tier.data, () => {
            console.log('UPDATED')
        })
    }

    return (
        <Private>
            <AdminLayout>
                <h1 className="text-2xl font-bold">Tier {tier?.name}</h1>
                <Link to="/admin/tiers" className="inline-flex mb-8">
                    Back to tiers
                </Link>

                <form
                    className="flex flex-col items-start justify-start"
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col">
                        <strong>ID:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={tier?._id}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>NAME:</strong>{' '}
                        <input
                            type="text"
                            value={tier?.name}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateTier',
                                    field: 'name',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>LIMIT:</strong>
                        <input
                            type="number"
                            value={tier?.limit}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateTier',
                                    field: 'limit',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>CREATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(tier?.createdAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>UPDATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(tier?.updatedAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col mt-4">
                        {tier?.changed ? (
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                            >
                                Save
                            </button>
                        ) : (
                            <div className="inline-flex items-center justify-center h-9 px-4 bg-black bg-opacity-10 text-white text-center cursor-not-allowed">
                                Save
                            </div>
                        )}
                    </div>
                </form>
            </AdminLayout>
        </Private>
    )
}

export default () => {
    const { tierId } = useParams()

    return (
        <TierProvider {...{ tierId }}>
            <AdminTier />
        </TierProvider>
    )
}
