import * as React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import '../../Sass/app.scss'
import { useAppState } from '../../Context'

import Loading from '@hoc/Loading'
import Router from '@hoc/Router'

// TODO: complete typing
export default (): React.ReactElement => {
    const { loading } = useAppState()

    if (loading) return <Loading />

    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    )
}
