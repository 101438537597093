import { ITierProviderStates, ITierProviderDto } from '@domain/Tier'
import Tier from '@classes/Tier'

const init = (state: ITierProviderStates, data: ITierProviderDto | null) =>
    data
        ? {
              ...state,
              ...data,
              tier: data?.tier ? new Tier(data?.tier) : null,
          }
        : state

const reducer = (state: any, { type, ...data }: any) => {
    switch (type) {
        case 'sync':
            return init(state, data)

        case 'updateTier':
            return {
                ...state,
                tier: state.tier.set(data.field, data.value),
            }

        default:
            return state
    }
}

export { init, reducer }
