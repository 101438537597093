import * as React from 'react'
import { Link } from 'react-router-dom'

interface ILink {
    to: string
    external?: boolean
    className?: string
    children: React.ReactNode
}

export default ({ to, external = false, className, children = '' }: ILink) => {
    if (!external) return <Link {...{ to, className }}>{children}</Link>

    return (
        <a
            href={to.includes('://') ? to : `https://${to}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={`${className} cursor-pointer`}
        >
            {children}
        </a>
    )
}
