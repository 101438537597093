import * as React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import NotFound from '@hoc/NotFound'

import Login from '@containers/Onboarding/Login'
import Signup from '@containers/Onboarding/Signup'
import ClaimAccount from '@containers/Onboarding/ClaimAccount'
import PendingVerification from '@containers/Onboarding/PendingVerification'

import Dashboard from '@containers/Dashboard'
import Downloads from '@containers/Downloads'
import Account from '@containers/Account'

import AdminApplications from '@containers/Admin/Applications'
import AdminMembers from '@containers/Admin/Members'
import AdminMember from '@containers/Admin/Member'
import AdminMemberNew from '@containers/Admin/MemberNew'
import AdminReporting from '@containers/Admin/Reporting'
import AdminRewards from '@containers/Admin/Rewards'
import AdminUsers from '@containers/Admin/Users'
import AdminUserNew from '@containers/Admin/UserNew'
import AdminUser from '@containers/Admin/User'
import AdminTiers from '@containers/Admin/Tiers'
import AdminTier from '@containers/Admin/Tier'
import AdminTierNew from '@containers/Admin/TierNew'
import AdminNotifications from '@containers/Admin/Notifications'

export default () => {
    return (
        <Routes>
            <Route path="*" element={<NotFound />} />

            <Route
                path="/onboarding"
                element={<Navigate to="/onboarding/signup" />}
            />
            <Route path="/onboarding/login" element={<Login />} />
            <Route path="/onboarding/signup" element={<Signup />} />
            <Route
                path="/onboarding/claim-account"
                element={<ClaimAccount />}
            />
            <Route
                path="/onboarding/pending-verification"
                element={<PendingVerification />}
            />

            <Route path="/">
                <Route index element={<Dashboard />} />

                <Route path="downloads" element={<Downloads />} />
                <Route path="account" element={<Account />} />

                <Route path="admin">
                    <Route index element={<AdminApplications />} />

                    <Route path="members">
                        <Route index element={<AdminMembers />} />
                        <Route path=":memberId" element={<AdminMember />} />
                        <Route path="new" element={<AdminMemberNew />} />
                    </Route>

                    <Route path="reporting" element={<AdminReporting />} />
                    <Route path="rewards" element={<AdminRewards />} />

                    <Route path="users">
                        <Route index element={<AdminUsers />} />
                        <Route path=":userId" element={<AdminUser />} />
                        <Route path="new" element={<AdminUserNew />} />
                    </Route>

                    <Route path="tiers" element={<AdminTiers />} />
                    <Route path="tiers/new" element={<AdminTierNew />} />
                    <Route path="tiers/:tierId" element={<AdminTier />} />
                    <Route
                        path="notifications"
                        element={<AdminNotifications />}
                    />
                </Route>
            </Route>
        </Routes>
    )
}
