import * as React from 'react'
import { Navigate, useLocation } from 'react-router'
import Link from '@components/Link'
import { useAppState } from '../../../Context'
import { canViewMenuItem } from '@functions/menuItems'

interface IBaseLayoutProps {
    children: React.ReactNode
}

export default ({ children }: IBaseLayoutProps) => {
    const { socket, user, handleLogout } = useAppState()
    const { pathname } = useLocation()

    if (socket === null || !user) return <Navigate to="/onboarding/login" />

    return (
        <div className="flex-1 flex h-screen overscroll-x-none">
            <div className="flex flex-col justify-between w-32 p-4 h-full border-r border-black">
                <div>
                    <Link
                        to="/"
                        className={`flex h-16 items-center justify-center text-center hover:bg-black hover:text-white ${
                            pathname === '/' ? 'font-bold' : 'font-normal'
                        } text-sm rounded-lg`}
                    >
                        Dashboard
                    </Link>
                    <Link
                        to="/downloads"
                        className={`flex h-16 items-center justify-center text-center hover:bg-black hover:text-white text-sm ${
                            pathname === '/downloads'
                                ? 'font-bold'
                                : 'font-normal'
                        } rounded-lg`}
                    >
                        Downloads
                    </Link>
                    <Link
                        to="/account"
                        className={`flex h-16 items-center justify-center text-center hover:bg-black hover:text-white text-sm ${
                            pathname === '/account'
                                ? 'font-bold'
                                : 'font-normal'
                        } rounded-lg`}
                    >
                        Account
                    </Link>

                    {canViewMenuItem(['super', 'admin'], user) ? (
                        <>
                            <Link
                                to="/admin"
                                className="flex h-16 items-center justify-center text-center hover:bg-black hover:text-white text-sm rounded-lg"
                            >
                                Admin
                            </Link>
                        </>
                    ) : null}
                </div>

                <button
                    onClick={handleLogout}
                    className="flex w-16 h-16 items-center justify-center mx-auto text-center bg-black text-white rounded-lg"
                >
                    Lo
                </button>
            </div>

            <div className="flex-1 h-screen p-12 relative overflow-y-scroll">
                {children}
            </div>
        </div>
    )
}
