import * as React from 'react'
import {
    createContext,
    useContext,
    useState,
    useEffect,
    useReducer,
} from 'react'
import { useAppState } from '@context/AppProvider'
import { IMemberProviderStates } from '@domain/Member'
import { IUserDoc } from '@domain/User'
import { init, reducer } from './reducer'

interface IMemberProviderProps {
    memberId?: string
    children: React.ReactNode
}

const MemberStateContext = createContext<IMemberProviderStates>({
    member: null,
    loading: true,
    setLoading: null,
})

const MemberProvider = ({
    memberId,
    children,
}: IMemberProviderProps): React.ReactElement => {
    const [loading, setLoading] = useState(true)
    const { socket } = useAppState()

    const [{ member }, dispatch] = useReducer(
        reducer,
        init(
            {
                memberId,
                member: null,
                loading: true,
                setLoading: null,
            },
            null
        )
    )

    useEffect(() => {
        if (socket === null) return

        socket.emit(
            'member_get',
            memberId,
            ({ member }: { member: IUserDoc[] }) => {
                dispatch({
                    type: 'sync',
                    member,
                    loading: false,
                })

                setLoading(false)
            }
        )
    }, [socket])

    return (
        <MemberStateContext.Provider
            {...{
                value: {
                    dispatch,
                    memberId,
                    member,
                    loading,
                    setLoading,
                },
            }}
        >
            {children}
        </MemberStateContext.Provider>
    )
}

const useMemberState = () => {
    const context = useContext(MemberStateContext)

    if (context === undefined)
        throw new Error('useMemberState must be used within a MemberProvider')

    return context
}

export { MemberProvider, useMemberState }
