import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { withContext } from './Context'
import App from '@hoc/App'
import { IClientWrapperProps } from '@domain/App'

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')

const root = createRoot(rootElement)
// const history = createBrowserHistory({ window })

const ClientWrapper = withContext(({ children }: IClientWrapperProps) => (
    <HelmetProvider>{children}</HelmetProvider>
))

root.render(
    <ClientWrapper>
        <App />
    </ClientWrapper>
)
