import * as React from 'react'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { useAppState } from '@context/AppProvider'
import User from '@classes/User'
import Link from '@components/Link'

const AdminMembers: React.FC = () => {
    const { users } = useAppState()
    const members = users?.filter(
        ({ scope, verified, claimed }) =>
            scope === 'user' && verified && claimed
    )

    return (
        <Private>
            <AdminLayout>
                <div className="flex items-center mb-8">
                    <h1 className="mr-8 text-2xl font-bold">Members</h1>
                    <Link
                        to="/admin/members/new"
                        className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                    >
                        Add New
                    </Link>
                </div>

                {members?.map(
                    ({ _id, firstName, lastName, email, tier }: User) => (
                        <Link
                            key={_id}
                            to={`/admin/members/${_id}`}
                            className="flex h-10 items-center justify-start border-b border-black"
                        >
                            <span>
                                {firstName} {lastName}
                            </span>
                            <span className="ml-4">{email}</span>
                            <span className="ml-4">{tier?.name}</span>
                        </Link>
                    )
                )}
            </AdminLayout>
        </Private>
    )
}

export default AdminMembers
