import * as React from 'react'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { INotificationDoc } from '@domain/Notification'
import { useAppState } from '@context/AppProvider'
import Link from '@components/Link'

interface IReadRes {
    success: boolean
}

const AdminNotifications: React.FC = () => {
    const { socket, notifications } = useAppState()

    const handleRead = async (_id: string) => {
        await socket?.emit(
            'notification_read',
            { _id },
            ({ success }: IReadRes) => {
                console.log(success)
            }
        )
    }

    return (
        <Private>
            <AdminLayout>
                <h1 className="mb-8 text-2xl font-bold">Notifications</h1>

                {notifications.map(
                    ({
                        _id,
                        scope,
                        message,
                        link,
                        read,
                        clusterId,
                    }: INotificationDoc) => (
                        <div className="flex h-16 items-center justify-start border-b border-black">
                            <span className="font-semibold">{message}</span>
                            <span className="ml-4">
                                <Link
                                    to={link}
                                    external={true}
                                    className="inline-flex items-center justify-center h-7 px-2 border border-black text-sm text-center font-medium rounded-full"
                                >
                                    Link
                                </Link>
                            </span>

                            <div className="flex items-center justify-start ml-4">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center h-7 px-2 border border-black text-sm text-center font-medium rounded-full"
                                    onClick={() => handleRead(_id)}
                                >
                                    Mark as read
                                </button>
                            </div>
                        </div>
                    )
                )}
            </AdminLayout>
        </Private>
    )
}

export default AdminNotifications
