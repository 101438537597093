import * as React from 'react'
import { useLocation, Navigate } from 'react-router'
import Link from '@components/Link'
import { useAppState } from '../../../Context'

interface IAdminLayoutProps {
    children: React.ReactNode
}

export default ({ children }: IAdminLayoutProps) => {
    const { pathname } = useLocation()
    const { socket, user, notifications, handleLogout } = useAppState()

    if (socket === null || !user) return <Navigate to="/onboarding/login" />

    if (user?.scope && !['super', 'admin'].includes(user.scope))
        return <Navigate to="/" />

    const unreadNotifications = notifications.filter(({ read }) => !read)

    return (
        <div className="flex-1 flex h-screen overscroll-x-none">
            <div className="flex flex-col justify-between w-56 h-full p-4 border-r border-black">
                <div>
                    <Link
                        to="/"
                        className="flex mt-4 h-16 px-4 items-center justify-center text-center hover:bg-black hover:text-white rounded-lg"
                    >
                        {`<`}
                    </Link>
                    <Link
                        to="/admin"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname === '/admin'
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        New Applications
                    </Link>
                    <Link
                        to="/admin/members"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname === '/admin/members'
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        Current Members
                    </Link>
                    <Link
                        to="/admin/reporting"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname === '/admin/reporting'
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        Reporting
                    </Link>
                    <Link
                        to="/admin/rewards"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname === '/admin/rewards'
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        Rewards
                    </Link>
                    <Link
                        to="/admin/users"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname.includes('/admin/users')
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        Users
                    </Link>
                    <Link
                        to="/admin/tiers"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname.includes('/admin/tiers')
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        Tiers
                    </Link>
                    <Link
                        to="/admin/notifications"
                        className={`flex mt-4 h-16 px-4 items-center justify-center text-center ${
                            pathname.includes('/admin/notifications')
                                ? 'bg-black text-white'
                                : 'hover:bg-black hover:text-white'
                        } text-sm rounded-lg`}
                    >
                        Notifications
                        {unreadNotifications.length > 0 ? (
                            <span className="inline-flex ml-2">
                                ({unreadNotifications.length})
                            </span>
                        ) : null}
                    </Link>
                </div>

                <button
                    onClick={handleLogout}
                    className="flex w-16 h-16 items-center justify-center mx-auto text-center bg-black text-white rounded-lg"
                >
                    Lo
                </button>
            </div>

            <div className="flex-1 h-screen p-12 relative overflow-y-scroll">
                {children}
            </div>
        </div>
    )
}
