import * as React from 'react'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { UserProvider, useUserState } from '@context/UserProvider'
import { useAppState } from '@context/AppProvider'
import Link from '@components/Link'
import { IUserDoc } from '@domain/User'

const AdminUserNew = () => {
    const { socket } = useAppState()
    const { dispatch, user } = useUserState()
    const navigate = useNavigate()

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        await socket?.emit('user_create', user?.data, (newUser: IUserDoc) => {
            if (newUser?._id) return navigate(`/admin/users/${newUser._id}`)
            else alert('Could not create user')
        })
    }

    return (
        <Private>
            <AdminLayout>
                <h1 className="text-2xl font-bold">
                    {user?.firstName} {user?.lastName}
                </h1>
                <Link to="/admin/users" className="inline-flex mb-8">
                    Back to users
                </Link>

                <form
                    className="flex flex-col items-start justify-start"
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col">
                        <strong>EMAIL:</strong>{' '}
                        <input
                            type="text"
                            value={user?.email || ''}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'email',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>FIRST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.firstName || ''}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'firstName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>LAST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.lastName || ''}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'lastName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>PASSWORD:</strong>{' '}
                        <input
                            type="password"
                            value={user?.password || ''}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'password',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>SCOPE:</strong>{' '}
                        <select
                            value={user?.scope || ''}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'scope',
                                    value,
                                })
                            }
                            required
                        >
                            <option value="admin">Admin</option>
                            <option value="super">Super</option>
                        </select>
                    </div>

                    <div className="flex flex-col mt-4">
                        {user?.valid() ? (
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                            >
                                Create
                            </button>
                        ) : (
                            <div className="inline-flex items-center justify-center h-9 px-4 bg-black bg-opacity-10 text-white text-center cursor-not-allowed">
                                Create
                            </div>
                        )}
                    </div>
                </form>
            </AdminLayout>
        </Private>
    )
}

export default () => {
    const { userId } = useParams()

    return (
        <UserProvider {...{ userId }}>
            <AdminUserNew />
        </UserProvider>
    )
}
