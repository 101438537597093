import { ITier, ITierDoc } from '@domain/Tier'

export default class Tier {
    private state: ITier = {}

    get _id() {
        return this.state._id
    }

    get name() {
        return this.state.name
    }

    get limit() {
        return this.state.limit
    }

    get createdAt() {
        return this.state.createdAt
    }

    get updatedAt() {
        return this.state.updatedAt
    }

    get count() {
        return this.state.count
    }

    get changed() {
        return this.state.changed
    }

    get data() {
        return {
            _id: this.state._id,
            name: this.state.name,
            limit: this.state.limit,
            count: this.state.count,
        }
    }

    constructor({ _id, name, limit, createdAt, updatedAt, count }: ITierDoc) {
        this.set('_id', _id)
        this.set('name', name)
        this.set('limit', limit)
        this.set('createdAt', createdAt)
        this.set('updatedAt', updatedAt)
        this.set('count', count)

        this.setChanged(false)
    }

    setId(_id?: string) {
        this.state._id = _id
    }

    setName(name?: string) {
        this.state.name = name
    }

    setLimit(limit?: number) {
        this.state.limit = limit
    }

    setCreatedAt(createdAt?: Date) {
        this.state.createdAt = createdAt
    }

    setUpdatedAt(updatedAt?: Date) {
        this.state.updatedAt = updatedAt
    }

    setCount(count: number) {
        this.state.count = count
    }

    setChanged(changed: boolean) {
        this.state.changed = changed
    }

    set(field: string, value: string | number | Date) {
        switch (field) {
            case '_id':
                if (typeof value === 'string') this.setId(value)
                break

            case 'name':
                if (typeof value === 'string') this.setName(value)
                break

            case 'limit':
                if (typeof value === 'number') this.setLimit(value)
                break

            case 'createdAt':
                if (typeof value === 'string')
                    this.setCreatedAt(new Date(value))
                break

            case 'updatedAt':
                if (typeof value === 'string')
                    this.setUpdatedAt(new Date(value))
                break

            case 'count':
                if (typeof value === 'number') this.setCount(value)
                break
        }

        this.setChanged(true)

        return this
    }

    valid() {
        if (!this.state.name || this.state.name.length === 0) return false
        if (!this.state.limit || this.state.name.length === 0) return false

        return true
    }
}
