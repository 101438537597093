import { IUserProviderStates, IUserProviderDto } from '@domain/User'
import User from '@classes/User'

const init = (state: IUserProviderStates, data: IUserProviderDto | null) => {
    return data
        ? {
              ...state,
              ...data,
              user: data?.user
                  ? new User(data?.user)
                  : new User(null, { scope: 'admin' }),
          }
        : state
}

const reducer = (state: any, { type, ...data }: any) => {
    switch (type) {
        case 'sync':
            return init(state, data)

        case 'updateUser':
            return {
                ...state,
                user: state.user.set(data.field, data.value),
            }

        default:
            return state
    }
}

export { init, reducer }
