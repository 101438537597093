import * as React from 'react'
import { useParams } from 'react-router-dom'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { MemberProvider, useMemberState } from '@context/MemberProvider'
import { useAppState } from '@context/AppProvider'
import Link from '@components/Link'
import Tier from '@classes/Tier'

const AdminMember = () => {
    const { socket, tiers } = useAppState()
    const { dispatch, member } = useMemberState()

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        if (socket === null || !member) return

        await socket.emit('user_update', member.data, () => {
            alert('UPDATED')
        })
    }

    return (
        <Private>
            <AdminLayout>
                <h1 className="text-2xl font-bold">
                    {member?.firstName} {member?.lastName}
                </h1>
                <Link to="/admin/members" className="inline-flex mb-8">
                    Back to members
                </Link>

                <form
                    className="flex flex-col items-start justify-start"
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col">
                        <strong>ID:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={member?._id}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>EMAIL:</strong>{' '}
                        <input
                            type="text"
                            value={member?.email}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'email',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>FIRST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={member?.firstName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'firstName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>LAST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={member?.lastName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'lastName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>SCOPE:</strong>{' '}
                        <select
                            value={member?.scope}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'scope',
                                    value,
                                })
                            }
                        >
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                            <option value="super">Super</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <strong>CHANNEL NAME:</strong>{' '}
                        <input
                            type="text"
                            value={member?.channelName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'channelName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>CHANNEL URL:</strong>{' '}
                        <input
                            type="text"
                            value={member?.channelUrl}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'channelUrl',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>STREAMING PLATFORM:</strong>{' '}
                        <select
                            name="streamingPlatform"
                            value={member?.streamingPlatform}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'streamingPlatform',
                                    value,
                                })
                            }
                        >
                            <option value="Twitch">Twitch</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <strong>TWITCH LINKED:</strong>{' '}
                        {member?.twitchLinked ? 'Yes' : 'No'}
                    </div>
                    <div className="flex flex-col">
                        <strong>TWITCH USERNAME:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={member?.twitchUsername}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>TIER:</strong>{' '}
                        <select
                            value={member?.tier?._id}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'tier',
                                    value: tiers?.find(
                                        ({ _id }) => _id === value
                                    ),
                                })
                            }
                        >
                            {tiers.map(
                                (
                                    { _id, name, count, limit }: Tier,
                                    index: number
                                ) => (
                                    <option
                                        key={index}
                                        disabled={
                                            _id !== member?.tier &&
                                            count >= (limit || 0)
                                        }
                                        value={_id}
                                    >
                                        {name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <strong>CREATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(member?.createdAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>UPDATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(member?.updatedAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>DELETED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(member?.deletedAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row items-center mt-4">
                        {member?.changed ? (
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                            >
                                Save
                            </button>
                        ) : (
                            <div className="inline-flex items-center justify-center h-9 px-4 bg-black bg-opacity-10 text-white text-center cursor-not-allowed">
                                Save
                            </div>
                        )}
                    </div>
                </form>
            </AdminLayout>
        </Private>
    )
}

export default () => {
    const { memberId } = useParams()

    return (
        <MemberProvider {...{ memberId }}>
            <AdminMember />
        </MemberProvider>
    )
}
