import * as React from 'react'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { UserProvider, useUserState } from '@context/UserProvider'
import { useAppState } from '@context/AppProvider'
import Link from '@components/Link'
import Tier from '@classes/Tier'

const AdminMemberNew = () => {
    const { tiers } = useAppState()
    const { dispatch, user } = useUserState()
    const navigate = useNavigate()

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        const response = await fetch('/api/users', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user?.data),
        })

        const newUser = await response.json()
        if (newUser?._id) return navigate(`/admin/users/${newUser._id}`)
    }

    return (
        <Private>
            <AdminLayout>
                <h1 className="text-2xl font-bold">
                    {user?.firstName} {user?.lastName}
                </h1>
                <Link to="/admin/members" className="inline-flex mb-8">
                    Back to members
                </Link>

                <form
                    className="flex flex-col items-start justify-start"
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col">
                        <strong>ID:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={user?._id}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>EMAIL:</strong>{' '}
                        <input
                            type="text"
                            value={user?.email}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'email',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>FIRST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.firstName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'firstName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>LAST NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.lastName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'lastName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>PASSWORD:</strong>{' '}
                        <input
                            type="password"
                            value={user?.password || ''}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'password',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>CHANNEL NAME:</strong>{' '}
                        <input
                            type="text"
                            value={user?.channelName}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'channelName',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>CHANNEL URL:</strong>{' '}
                        <input
                            type="text"
                            value={user?.channelUrl}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'channelUrl',
                                    value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>STREAMING PLATFORM:</strong>{' '}
                        <select
                            name="streamingPlatform"
                            value={user?.streamingPlatform}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'streamingPlatform',
                                    value,
                                })
                            }
                        >
                            <option value="Twitch">Twitch</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <strong>TIER:</strong>{' '}
                        <select
                            value={user?.tier?._id}
                            className="flex items-center w-full h-9 px-3 border border-black"
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'updateUser',
                                    field: 'tier',
                                    value: tiers?.find(
                                        ({ _id }) => _id === value
                                    ),
                                })
                            }
                            required
                        >
                            <option value="">Select a tier</option>

                            {tiers.map(
                                (
                                    { _id, name, count, limit }: Tier,
                                    index: number
                                ) => (
                                    <option
                                        key={index}
                                        disabled={
                                            _id !== user?.tier &&
                                            count >= (limit || 0)
                                        }
                                        value={_id}
                                    >
                                        {name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <strong>CREATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(user?.createdAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>UPDATED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(user?.updatedAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <strong>DELETED AT:</strong>{' '}
                        <input
                            type="text"
                            disabled
                            readOnly
                            value={JSON.stringify(user?.deletedAt)}
                            className="flex items-center w-full h-9 px-3 border border-black"
                        />
                    </div>

                    <div className="flex flex-col mt-4">
                        <button
                            type="submit"
                            className="inline-flex items-center justify-center h-9 px-4 bg-black text-white text-center"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </AdminLayout>
        </Private>
    )
}

export default () => {
    const { userId } = useParams()

    return (
        <UserProvider {...{ userId }}>
            <AdminMemberNew />
        </UserProvider>
    )
}
