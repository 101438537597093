import { IAppProviderStates, IAppProviderDto, IConnection } from '@domain/App'
import { IUserDoc, IUser } from '@domain/User'
import { ITierDoc, ITier } from '@domain/Tier'
import { INotificationDoc } from '@domain/Notification'
import User from '@classes/User'
import Tier from '@classes/Tier'
import Notification from '@classes/Notification'

const init = (state: IAppProviderStates, data: IAppProviderDto | null) =>
    data
        ? {
              ...data,
              user: data?.user ? new User(data?.user) : null,
              users: data?.users?.map((user: IUserDoc) => new User(user)),
              tiers: data?.tiers?.map((tier: ITierDoc) => new Tier(tier)),
          }
        : state

const updateUser = (
    { users, ...state }: IAppProviderStates,
    { user, tiers }: { user: IUserDoc; tiers: ITierDoc[] }
) => {
    return {
        ...state,
        users: users
            ? [
                  ...users.filter((u: User) => u?._id !== user?._id),
                  new User(user),
              ]
            : [new User(user)],
        tiers: tiers
            ? tiers.map((tier: ITierDoc) => new Tier(tier))
            : state.tiers,
    }
}

const removeUser = (
    { users, ...state }: IAppProviderStates,
    { user: _user }: { user: IUserDoc }
) => {
    return {
        ...state,
        users: users
            ? [...users.filter((u: IUser) => u?._id !== _user._id)]
            : [],
    }
}

const createUser = (
    { users, ...state }: IAppProviderStates,
    { user }: { user: IUserDoc }
) => {
    return {
        ...state,
        users: [...(users || []), new User(user)],
    }
}

const approveUser = (
    { users, tiers, ...state }: IAppProviderStates,
    { user }: { user: IUserDoc }
) => {
    return {
        ...state,
        users: users
            ? [
                  ...users.filter((u: User) => u?._id !== user?._id),
                  new User(user),
              ]
            : [new User(user)],
        tiers: tiers.map((tier: Tier) => {
            return tier._id === user?.tier?._id
                ? tier.set('count', tier.count + 1)
                : tier
        }),
    }
}

const createTier = (
    { tiers, ...state }: IAppProviderStates,
    { tier }: { tier: ITierDoc }
) => {
    return {
        ...state,
        tiers: [...tiers, new Tier(tier)],
    }
}

const updateTier = (
    { tiers, ...state }: IAppProviderStates,
    { tier }: { tier: ITierDoc }
) => {
    return {
        ...state,
        tiers: [...tiers.filter((t: ITier) => t?._id !== tier?._id), tier],
    }
}

const createNotification = (
    { notifications, ...state }: IAppProviderStates,
    { notification }: { notification: INotificationDoc }
) => {
    return {
        ...state,
        notifications: [...notifications, new Notification(notification)],
    }
}

const updateNotification = (
    { notifications, ...state }: IAppProviderStates,
    { notification }: { notification: INotificationDoc }
) => {
    return {
        ...state,
        notifications: [
            ...notifications.filter(
                (n: INotificationDoc) => n?._id !== notification?._id
            ),
            notification,
        ],
    }
}

const readNotification = (
    { notifications, ...state }: IAppProviderStates,
    { notification }: { notification: INotificationDoc }
) => {
    return {
        ...state,
        notifications: [
            ...notifications.filter(
                (n: INotificationDoc) => n?._id !== notification?._id
            ),
        ],
    }
}

const reducer = (state: any, { type, ...data }: any) => {
    switch (type) {
        case 'sync':
            return init(state, data)

        case 'addConnection':
            return {
                ...state,
                connections: state.connections
                    .filter(
                        (c: IConnection) => c?._id !== data?.connection?._id
                    )
                    .concat(data?.connection),
            }

        case 'removeConnection':
            return {
                ...state,
                connections: state.connections.filter(
                    (c: IConnection) => c?.socketId !== data?.connection
                ),
            }

        case 'updateUser':
            return updateUser(state, data)

        case 'removeUser':
            return removeUser(state, data)

        case 'createUser':
            return createUser(state, data)

        case 'approveUser':
            return approveUser(state, data)

        case 'createTier':
            return createTier(state, data)

        case 'updateTier':
            return updateTier(state, data)

        case 'createNotification':
            return createNotification(state, data)

        case 'updateNotification':
            return updateNotification(state, data)

        case 'readNotification':
            return readNotification(state, data)

        default:
            return state
    }
}

export { init, reducer }
