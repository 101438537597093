import * as React from 'react'
import {
    createContext,
    useContext,
    useState,
    useEffect,
    useReducer,
} from 'react'
import { io, Socket } from 'socket.io-client'
import { useCookies } from 'react-cookie'
import { IConnection, IAppProviderStates } from '@domain/App'
import { IUserDoc } from '@domain/User'
import { ITierDoc } from '@domain/Tier'
import { init, reducer } from './reducer'
import { INotificationDoc } from '@domain/Notification'

interface IAppProviderProps {
    children: React.ReactNode
}

interface ServerToClientEvents {
    success: (args?: any) => void
    error: (args?: any) => void
}

interface ClientToServerEvents {
    success: (args: string) => void
    error: (args: string) => void
}

const AppStateContext = createContext<IAppProviderStates>({
    socket: null,
    connections: [],
    notifications: [],
    tiers: [],
    loading: true,
    setLoading: null,
    handleLogout: () => true,
})

const AppProvider = ({ children }: IAppProviderProps): React.ReactElement => {
    const [cookies, setCookie, removeCookie] = useCookies([
        'swccp_access_token',
        'swccp_force_logout',
    ])
    const [loading, setLoading] = useState(true)

    const [
        { socket, user, users, connections, notifications, tiers },
        dispatch,
    ] = useReducer(
        reducer,
        init(
            {
                socket: null,
                connections: [],
                loading: true,
                notifications: [],
                tiers: [],
                setLoading: null,
                handleLogout: () => true,
            },
            null
        )
    )

    useEffect(() => {
        const checkApp = async () => {
            if (cookies.swccp_force_logout !== undefined) {
                console.log('FORCE LOGOUT')
            } else {
                if (cookies.swccp_access_token !== undefined) {
                    const socket: Socket<
                        ServerToClientEvents,
                        ClientToServerEvents
                    > = io(process.env.SERVER_URL, {
                        query: {
                            access_token: cookies.swccp_access_token,
                        },
                        transports: ['websocket'],
                    })

                    socket.on('success', (payload) => {
                        setLoading(false)
                        dispatch({ type: 'sync', socket, ...payload })
                    })

                    socket.on('error', (err: void) => {
                        setLoading(false)
                        console.log('error')
                        removeCookie('swccp_access_token', { path: '/' })
                    })

                    socket.on('connect_error', (err) => {
                        console.error(err)
                        removeCookie('swccp_access_token', { path: '/' })
                    })
                } else {
                    setLoading(false)
                }
            }
        }

        checkApp()
    }, [cookies.swccp_access_token])

    const addConnection = (data: IConnection) => {
        dispatch({ type: 'addConnection', connection: data })
    }
    const removeConnection = (data: IConnection) => {
        dispatch({ type: 'removeConnection', connection: data })
    }

    const updateUser = ({
        user,
        tiers,
    }: {
        user: IUserDoc
        tiers: ITierDoc[]
    }) => {
        dispatch({ type: 'updateUser', user, tiers })
    }

    const removeUser = (user: IUserDoc) => {
        dispatch({ type: 'removeUser', user })
    }

    const createUser = (user: IUserDoc) => {
        dispatch({ type: 'createUser', user })
    }

    const approveUser = (user: IUserDoc) => {
        dispatch({ type: 'approveUser', user })
    }

    const createTier = ({ tier }: { tier: ITierDoc }) => {
        dispatch({ type: 'createTier', tier })
    }

    const updateTier = (tier: ITierDoc) => {
        dispatch({ type: 'updateTier', tier })
    }

    const createNotification = (notification: INotificationDoc) => {
        dispatch({ type: 'createNotification', notification })
    }

    const readNotification = (notification: INotificationDoc) => {
        dispatch({ type: 'readNotification', notification })
    }

    const handleLogout = async () => {
        removeCookie('swccp_access_token', { path: '/' })
        removeCookie('swccp_force_logout')

        await socket.disconnect()

        dispatch({
            type: 'sync',
            socket: null,
            user: null,
            users: [],
            notifications: [],
            tiers: [],
            connections: [],
            loading: true,
            setLoading: null,
        })

        setLoading(false)
    }

    useEffect(() => {
        if (socket !== null) {
            socket.on('connected', addConnection)
            socket.on('disconnected', removeConnection)

            socket.on('user_updated', updateUser)
            socket.on('user_removed', removeUser)
            socket.on('user_created', createUser)
            socket.on('user_verify_approved', approveUser)

            socket.on('tier_created', createTier)
            socket.on('tier_updated', updateTier)

            socket.on('notification_created', createNotification)
            socket.on('notification_read_complete', readNotification)

            return () => {
                socket.removeListener('connected', addConnection)
                socket.removeListener('disconnected', removeConnection)

                socket.removeListener('user_updated', updateUser)
                socket.removeListener('user_removed', removeUser)
                socket.removeListener('user_created', createUser)
                socket.removeListener('user_verify_approved', approveUser)

                socket.removeListener('tier_created', createTier)
                socket.removeListener('tier_updated', updateTier)

                socket.removeListener(
                    'notification_created',
                    createNotification
                )
                socket.removeListener(
                    'notification_read_complete',
                    readNotification
                )
            }
        }
    }, [socket])

    return (
        <AppStateContext.Provider
            {...{
                value: {
                    socket,
                    user,
                    users,
                    connections,
                    notifications,
                    tiers,
                    loading,
                    setLoading,
                    handleLogout,
                },
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

const useAppState = () => {
    const context = useContext(AppStateContext)

    if (context === undefined)
        throw new Error('useAppState must be used within a AppProvider')

    return context
}

export { AppProvider, useAppState }
