import * as React from 'react'
import { AppProvider, useAppState } from './AppProvider'
import { CookiesProvider } from 'react-cookie'
import { IClientWrapperProps } from '@domain/App'

interface IWithContextProps {
    children: React.ReactElement
}

const withContext =
    (Component: React.ComponentType<IClientWrapperProps>) =>
    (props: IWithContextProps): React.ReactElement => {
        return (
            <CookiesProvider>
                <AppProvider>
                    <Component {...{ ...props }} />
                </AppProvider>
            </CookiesProvider>
        )
    }

export { AppProvider, useAppState, withContext }
