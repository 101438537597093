import * as React from 'react'
import { useState } from 'react'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'
import { useAppState } from '@context/AppProvider'
import Link from '@components/Link'
import User from '@classes/User'

interface IApprovalRes {
    success: boolean
}

interface IVerifyDenyRes {
    success: boolean
    tokenDeleted: boolean
    emailSent: boolean
}

const AdminApplications: React.FC = () => {
    const { socket, users } = useAppState()
    const [loading, setLoading] = useState<boolean>(false)
    const [fullTier, setFullTier] = useState<string | undefined>()
    const applicants = users?.filter(
        ({ verified, deletedAt }) => !verified && !deletedAt
    )

    const handleApprove = async (id?: string, tierId?: string) => {
        setLoading(true)

        await socket?.emit(
            'user_verify_approve',
            { id, tierId },
            ({ success }: IApprovalRes) => {
                if (success === false) setFullTier(tierId)
                setLoading(false)
            }
        )
    }

    const handleDeny = async (id?: string) => {
        setLoading(true)

        await socket?.emit('user_verify_deny', id, (res: IVerifyDenyRes) => {
            setLoading(false)
        })
    }

    return (
        <Private>
            <AdminLayout>
                <h1 className="mb-8 text-2xl font-bold">Applications</h1>
                <div>
                    {applicants && applicants.length > 0 ? (
                        <>
                            {applicants?.map(
                                (
                                    {
                                        _id,
                                        firstName,
                                        lastName,
                                        streamingPlatform,
                                        channelUrl,
                                        tier,
                                    }: User,
                                    index: number
                                ) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-start mt-4 py-4 border-t"
                                    >
                                        <span>
                                            {firstName} {lastName}
                                        </span>

                                        <span className="ml-4">
                                            {streamingPlatform}
                                        </span>

                                        {channelUrl ? (
                                            <span className="ml-4">
                                                <Link
                                                    to={channelUrl}
                                                    external={true}
                                                >
                                                    Link
                                                </Link>
                                            </span>
                                        ) : null}

                                        <span className="ml-4">
                                            {tier?.name}
                                        </span>

                                        <div className="flex items-center justify-start ml-4">
                                            <button
                                                className="inline-flex items-center justify-center w-6 h-6 border border-black text-center font-medium rounded-full"
                                                onClick={() =>
                                                    handleApprove(
                                                        _id,
                                                        tier?._id
                                                    )
                                                }
                                            >
                                                A
                                            </button>
                                            <button
                                                className="inline-flex items-center justify-center w-6 h-6 ml-1 border border-black text-center font-medium rounded-full"
                                                onClick={() => handleDeny(_id)}
                                            >
                                                D
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    ) : (
                        <div>No new applications</div>
                    )}
                </div>

                {fullTier ? (
                    <>
                        <div
                            className="w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-30 cursor-pointer"
                            onClick={() => setFullTier(undefined)}
                        />
                        <div className="flex flex-col items-center justify-center p-8 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-center">
                            <h3 className="mb-2 text-xl font-medium">
                                Cannot Approve!
                            </h3>
                            <p className="max-w-md">
                                It looks like this tier is full! Lorem, ipsum
                                dolor sit amet consectetur adipisicing elit.
                                Reprehenderit, nam.
                            </p>
                            <Link
                                to={`/admin/tiers/${fullTier}`}
                                className="inline-flex items-center justify-center h-10 mt-8 px-4 bg-black text-center text-white"
                            >
                                Configure Tiers
                            </Link>
                        </div>
                    </>
                ) : null}

                <span
                    className={`flex flex-col items-center justify-center w-full h-full absolute top-0 left-0 bg-white ${
                        loading
                            ? 'opacity-100 visible pointer-events-auto'
                            : 'opacity-0 invisible pointer-events-none'
                    }`}
                >
                    Loading...
                </span>
            </AdminLayout>
        </Private>
    )
}

export default AdminApplications
