import { INotification, INotificationDoc } from '@domain/Notification'

export default class Notification {
    private state: INotification = {}

    get id() {
        return this.state._id
    }

    get user() {
        return this.state.user
    }

    get scope() {
        return this.state.scope
    }

    get message() {
        return this.state.message
    }

    get link() {
        return this.state.link
    }

    get read() {
        return this.state.read
    }

    get createdAt() {
        return this.state.createdAt
    }

    get updatedAt() {
        return this.state.updatedAt
    }

    get data() {
        return {}
    }

    constructor({
        _id,
        scope,
        message,
        link,
        read,
        clusterId,
        createdAt,
        updatedAt,
    }: INotificationDoc) {
        this.set('_id', _id)
        this.set('scope', scope)
        this.set('message', message)
        this.set('link', link)
        this.set('read', read)
        this.set('clusterId', clusterId)
        this.set('createdAt', createdAt)
        this.set('updatedAt', updatedAt)
    }

    setId(id?: string) {
        this.state._id = id
    }

    setScope(scope?: [string]) {
        this.state.scope = scope
    }

    setMessage(message?: string) {
        this.state.message = message
    }

    setLink(link?: string) {
        this.state.link = link
    }

    setRead(read?: boolean) {
        this.state.read = read
    }

    setClusterId(clusterId?: string) {
        this.state.clusterId = clusterId
    }

    setCreatedAt(createdAt?: Date) {
        this.state.createdAt = createdAt
    }

    setUpdatedAt(updatedAt?: Date) {
        this.state.updatedAt = updatedAt
    }

    set(field: string, value: string | number | boolean | Date | [string]) {
        switch (field) {
            case 'id':
                if (typeof value === 'string') this.setId(value)
                break

            case 'scope':
                if (Array.isArray(value)) this.setScope(value)
                break

            case 'message':
                if (typeof value === 'string') this.setMessage(value)
                break

            case 'link':
                if (typeof value === 'string') this.setLink(value)
                break

            case 'read':
                if (typeof value === 'boolean') this.setRead(value)
                break

            case 'clusterId':
                if (typeof value === 'string') this.setClusterId(value)
                break

            case 'createdAt':
                if (value instanceof Date) this.setCreatedAt(value)
                break

            case 'updatedAt':
                if (value instanceof Date) this.setUpdatedAt(value)
                break
        }

        return this
    }

    valid() {
        return true
    }
}
