import React from 'react'
import Onboarding from '@hoc/Onboarding'

const PendingVerification = () => {
    return (
        <Onboarding path="pending-verification">
            <div className="flex flex-col items-center justify-center w-screen h-screen p-8 fixed top-0 left-0 text-center">
                <h2 className="max-w-lg mx-auto mb-8 p-2 text-xl font-bold text-center">
                    Thank you, your application has been received and is in
                    review with Zynga
                </h2>

                <div className="max-w-lg">We will be in touch shortly.</div>
            </div>
        </Onboarding>
    )
}

export default PendingVerification
