import * as React from 'react'
import {
    createContext,
    useContext,
    useState,
    useEffect,
    useReducer,
} from 'react'
import { useAppState } from '@context/AppProvider'
import { IUserProviderStates, IUserDoc } from '@domain/User'
import { init, reducer } from './reducer'

interface IUserProviderProps {
    userId?: string
    children: React.ReactNode
}

const UserStateContext = createContext<IUserProviderStates>({
    user: null,
    loading: true,
    setLoading: null,
})

const UserProvider = ({
    userId,
    children,
}: IUserProviderProps): React.ReactElement => {
    const [loading, setLoading] = useState(true)
    const { socket } = useAppState()

    const [{ user }, dispatch] = useReducer(
        reducer,
        init(
            {
                userId,
                user: null,
                loading: true,
                setLoading: null,
            },
            null
        )
    )

    useEffect(() => {
        if (socket === null) return

        socket.emit('user_get', userId, ({ user }: { user: IUserDoc[] }) => {
            dispatch({
                type: 'sync',
                user,
                loading: false,
            })

            setLoading(false)
        })
    }, [socket])

    return (
        <UserStateContext.Provider
            {...{
                value: {
                    dispatch,
                    userId,
                    user,
                    loading,
                    setLoading,
                },
            }}
        >
            {children}
        </UserStateContext.Provider>
    )
}

const useUserState = () => {
    const context = useContext(UserStateContext)

    if (context === undefined)
        throw new Error('useUserState must be used within a UserProvider')

    return context
}

export { UserProvider, useUserState }
