import React from 'react'
import Onboarding from '@hoc/Onboarding'

const ClaimAccount = () => {
    return (
        <Onboarding path="claim-account">
            <div className="flex flex-col items-center justify-center w-screen h-screen p-8 fixed top-0 left-0 text-center">
                <h2 className="max-w-lg mx-auto mb-8 p-2 text-xl font-bold text-center">
                    Thank you for applying to the Star Wars: Hunters Creator
                    Platform.
                </h2>

                <div className="max-w-lg">
                    Please verify your application via the email we just sent
                    you.
                </div>
            </div>
        </Onboarding>
    )
}

export default ClaimAccount
