import * as React from 'react'
import Private from '@hoc/Private'
import AdminLayout from '@hoc/Layout/Admin'

const AdminReporting: React.FC = () => {
    return (
        <Private>
            <AdminLayout>
                <h1 className="mb-8 text-2xl font-bold">Reporting</h1>
            </AdminLayout>
        </Private>
    )
}

export default AdminReporting
