import * as React from 'react'
import {
    createContext,
    useContext,
    useState,
    useEffect,
    useReducer,
} from 'react'
import { useAppState } from '@context/AppProvider'
import { ITierProviderStates, ITierDoc } from '@domain/Tier'
import { init, reducer } from './reducer'

interface ITierProviderProps {
    tierId?: string
    children: React.ReactNode
}

const TierStateContext = createContext<ITierProviderStates>({
    tier: null,
    loading: true,
    setLoading: null,
})

const TierProvider = ({
    tierId,
    children,
}: ITierProviderProps): React.ReactElement => {
    const [loading, setLoading] = useState(true)
    const { socket } = useAppState()

    const [{ tier }, dispatch] = useReducer(
        reducer,
        init(
            {
                tierId,
                tier: null,
                loading: true,
                setLoading: null,
            },
            null
        )
    )

    useEffect(() => {
        if (socket === null) return

        socket.emit('tier_get', tierId, ({ tier }: { tier: ITierDoc[] }) => {
            dispatch({
                type: 'sync',
                tier,
                loading: false,
            })

            setLoading(false)
        })
    }, [socket])

    return (
        <TierStateContext.Provider
            {...{
                value: {
                    dispatch,
                    tierId,
                    tier,
                    loading,
                    setLoading,
                },
            }}
        >
            {children}
        </TierStateContext.Provider>
    )
}

const useTierState = () => {
    const context = useContext(TierStateContext)

    if (context === undefined)
        throw new Error('useTierState must be used within a TierProvider')

    return context
}

export { TierProvider, useTierState }
